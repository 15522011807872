<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class=" column q-gutter-sm">
        <q-select
          outlined
          v-if="user.is_super_user == '1'"
          v-model="selMusyrif"
          label="Musyrif"
          :options="musyrif"
          @input="
            selRole = null;
            listSiswa = [];
            filteredSiswa = [];
            getoptrole();
          "
        />
        <q-select
          :options="listRole"
          v-model="selRole"
          outlined
          label="Saya Seorang..."
          @input="
            pagination = {
              current: 1,
              max: 99,
              limit: 10,
            };
            getsiswa();
          "
        ></q-select>
        <q-btn
          icon="add"
          color="primary"
          label="Tambah Siswa"
          :disable="!selRole"
          @click="toAdd"
        ></q-btn>
      </q-card-section>
      <q-separator></q-separator>
      <q-input
        outlined
        dense
        label="Cari Nama Siswa"
        v-model="searchTerm"
        @input="getshow"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="min-height:calc(100vh - 315px)"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nama</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td
              @click="
                selsiswa = val;
                detail = true;
              "
            >
              {{ (pagination.current - 1) * 10 + (i + 1) }}
            </td>
            <td
              @click="
                selsiswa = val;
                detail = true;
              "
            >
              {{ val.nama }}
            </td>
            <td>
              <!-- <q-btn
                flat
                dense
                color="primary"
                icon="info"
                @click="
                  
                "
              ></q-btn> -->
              <q-select
                dense
                outlined
                map-options
                emit-value
                label="Status"
                :options="optHadir"
                v-model="val.is_hadir"
              ></q-select>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions align="stretch">
        <!-- <q-btn outline color="primary" label="Export Excel"></q-btn> -->
        <!-- <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="getshow"
        /> -->
        <q-btn
          unelevated
          color="secondary"
          label="SIMPAN STATUS"
          class="full-width"
          @click="simpan"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="confirm" v-if="selsiswa">
      <q-card>
        <q-card-section>
          Apa anda yakin ingin menghapus {{ selsiswa.nama }} dari List Binaan
          {{ selRole.label }} anda?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Ya"
            color="negative"
            @click="hapus"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="detail" v-if="selsiswa">
      <q-card>
        <q-separator></q-separator>
        <a class="text-grey-9 text-h6 text-bold q-ml-md">Data Siswa</a>
        <q-markup-table>
          <tr>
            <td>Nama Siswa</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama) }}</td>
          </tr>
          <tr>
            <td>Nama Musyrif</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_musyrif) }}</td>
          </tr>
          <tr>
            <td>Kontak Musyrif</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_musyrif) }}</td>
          </tr>
          <tr>
            <td>Nama Kelas</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_kelas) }}</td>
          </tr>
          <tr>
            <td>Nama Walas</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_walas) }}</td>
          </tr>
          <tr>
            <td>Kontak Walas</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_walas) }}</td>
          </tr>
          <tr>
            <td>Nama Kamar</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_kamar) }}</td>
          </tr>
          <tr>
            <td>Ketua Kamar</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_ketua) }}</td>
          </tr>
        </q-markup-table>

        <q-separator></q-separator>
        <a class="text-grey-9 text-h6 text-bold q-ml-md">Data Keluarga</a>
        <q-markup-table>
          <tr>
            <td>Nama Ayah</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_ayah) }}</td>
          </tr>
          <tr>
            <td>Kontak Ayah</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_ayah) }}</td>
          </tr>
          <tr>
            <td>Nama Ibu</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.nama_ibu) }}</td>
          </tr>
          <tr>
            <td>Kontak Ibu</td>
            <td>:</td>
            <td>{{ nullfilter(selsiswa.kontak_ibu) }}</td>
          </tr>
        </q-markup-table>

        <q-card-actions align="between">
          <q-btn
            unelevated
            color="negative"
            label="Hapus"
            @click="
              detail = false;
              confirm = true;
            "
          ></q-btn>
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      detail: false,
      confirm: false,
      listRole: [
        { label: "Musyrif/ah", value: "id_musyrif" },
        { label: "Murabbi Tahfidz", value: "id_murabbi_tahfidz" },
        { label: "Murabbi Mentor", value: "id_murabbi_mentor" },
      ],
      selRole: null,
      searchTerm: "",

      optHadir: [
        { label: "Hadir", value: "1" },
        { label: "Pulang", value: "0" },
      ],

      user: {},
      musyrif: [],
      selMusyrif: null,

      listSiswa: [],
      filteredSiswa: [],
      selsiswa: null,

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    await this.getuser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif = this.user;
      this.selMusyrif.value = this.selMusyrif.id;
    }
    this.getoptrole();
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listSiswa;
      else {
        return this.listSiswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.put(`/pengaturan/bimbingan/edithadir`, this.listSiswa);
      this.$q.loading.hide();
      await this.getsiswa();
      this.$q.notify({
        message: "Status Kehadiran siswa berhasil dirubah",
        color: "positive",
      });
    },
    async getuser() {
      this.user = await this.getCurrentUser();
    },
    nullfilter(data) {
      if (data == null || data == "") {
        return "-";
      } else {
        return data;
      }
    },
    async editHadir(id, val) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.put(`/pengaturan/bimbingan/edithadir/${id}/${val}`);
      this.$q.loading.hide();
    },
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    toAdd() {
      this.$router.push(
        `/pengaturaneditbimbingan/${this.selMusyrif.value}/${this.selRole.value}`
      );
    },
    async hapus() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.put(
        `/pengaturan/bimbingan/delsiswa/${this.selsiswa.id}/${this.selRole.value}`
      );
      this.$q.loading.hide();
      await this.getsiswa();
      // this.getshow();
    },
    getoptrole() {
      let temp = [];
      if (this.selMusyrif.is_musyrif == "1") {
        temp.push({ label: "Musyrif/ah", value: "id_musyrif" });
      }
      if (this.selMusyrif.is_murabbi_tahfidz == "1") {
        temp.push({ label: "Murabbi Tahfidz", value: "id_murabbi_tahfidz" });
      }
      if (this.selMusyrif.is_murabbi_mentor == "1") {
        temp.push({ label: "Murabbi Mentor", value: "id_murabbi_mentor" });
      }
      this.listRole = temp;
    },
    async getsiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/bimbingan/getsiswa/${this.selMusyrif.value}/${this.selRole.value}`
      );
      this.listSiswa = resp.data;
      this.$q.loading.hide();
      // this.getshow();
    },
    getshow() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let siswa = JSON.parse(JSON.stringify(this.listSiswa));

      if (this.searchTerm != "") {
        siswa = siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(siswa.length / 10);
      this.filteredSiswa = show;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
